<template>
    <div class="lists-wrap">

        <div class="list-info">
            <span>Shopping Lists</span>
        </div>

        

        <div class="my-list">
            <h2>My List</h2>  
            <p class="my-list-name" @click="loadList(list)">{{ list.list_name }}</p>
        </div>



        <hr class="lists-hr">



        <div class="member-of-lists" v-if="memberOf[0]">
            <h2>Member of Lists</h2>
            <ul>
                
                <li v-for="mlist in memberOf" :key="mlist.list_id">
                    <p class="member-list-name" @click="loadList(mlist)">{{ mlist.list_name }}</p>
                    <p>-</p>
                    <p class="owner-name">{{ mlist.owner }}</p>
                </li>

            </ul>
        </div>




        <div class="help-lists-wrap" @click="showListHelp">
            <span class="material-icons help-lists">help</span>
        </div>


    </div>
</template>

<script>

import { mapGetters } from 'vuex'


export default {
    name: 'Lists',

    computed: {
        ...mapGetters(['memberOf']),
        ...mapGetters(['list']),
    },

    methods:  {
        loadList: function(mlist) {
            this.$store.dispatch('loadListItems', mlist)
        },

        showListHelp: function() {
            var modal = {
                show: true,
                autoClose: false,
                general: true,
                success: false,
                options: false,
                input: false,
                heading: 'Lists',
                body:   '<p>Click on a list to view, edit or add items.</p>'
                        +'<br>'
                        +"<h4>Member's lists:</h4>"
                        +"<p>You can only add or edit another user's lists if they have given you permission to do so.</p>",
                data: {} 
        }

        this.$store.dispatch('showModal', modal)
        }
    },


}
</script>




<style>

.lists-wrap {
    width: 100%;
    
}

.my-list {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding-left: 1em;
}

.my-list-name {
    color: rgb(0, 139, 219);
    padding-left: 0.5em;
    margin-top: 0.5em;
}

.lists-hr {
    width: 60vw;
    border: none;
    height: 1px;
    background: rgb(72, 188, 255);
    padding-left: 0;
}


.member-of-lists {
    margin-top: 1.5em;
    width: 100%;
    padding-left: 1em;
}

.member-of-lists ul li{
    width: 100%;
    /*
    display: grid;
    grid-template-columns: 1fr 0.2fr 1.2fr;
    list-style-type: none;
    margin-bottom: 0.5em;
    justify-items: center;
    padding: 0 0.5em;
    */
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 0.8em;
}

.member-of-lists h2 {
    margin-bottom: 1em;
}


.member-list-name {
    color: rgb(0, 139, 219);
    justify-self: left;
    padding: 0 1em;
}

.owner-name {
    justify-self: left;
    padding: 0 1em;
}



.help-lists-wrap {
    position: fixed;
    bottom: 52px;
    right: 1em;
    height: 30px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.help-lists-wrap .material-icons.help-lists {
    color :rgb(0,150,240);
    font-size: 24px;
}

</style>