<template>
    <div class="lists-wrap">

        <div class="list-info">
            <span>Combine Shopping Lists</span>
        </div>

        
        <div class="my-list">
            <h2>My List</h2> 
            <div class="inline-list-select">
                <input class="list-select-checkbox" type="checkbox" :checked="selectAll" :value="list.list_id" v-model="listsToFetch">
                <p class="my-list-name">{{ list.list_name }}</p>
            </div> 
        </div>

        <hr class="lists-hr">

        <div class="member-of-lists" v-if="memberOf[0]">
            <h2>Member of Lists</h2>
            <ul>
                
                <li v-for="mlist in memberOf" :key="mlist.list_id">


                    <div class="inline-list-select">
                        <input type="checkbox" :checked="selectAll" :value="mlist.list_id" v-model="listsToFetch">
                        <p class="member-list-name">{{ mlist.list_name }}</p>
                    </div>

                    <p>-</p>

                    <p class="owner-name">{{ mlist.owner }}</p>


                </li>

            </ul>
        </div>

        <div class="inline-list-select my-list">
            <input class="select-all-checkbox" type="checkbox" @click="selectAll" v-model="allSelected">
            <p class="my-list-name">Select All</p>
        </div>

        <div class="btn-combine-lists-wrap">
            <button class="btn btn-combine-lists" @click="combineLists">Combine</button>
        </div>


        <div class="help-lists-wrap" @click="showListHelp">
            <span class="material-icons help-lists">help</span>
        </div>


    </div>
</template>

<script>

import { mapGetters } from 'vuex'


export default {

    name: 'ChooseList',

    data() {
        return {
            listsToFetch: [],
            allSelected: false
        }
    },

    computed: {
        ...mapGetters(['memberOf']),
        ...mapGetters(['list']),
    },



    methods: {

        selectAll: function() {

            if(!this.allSelected) {
                this.listsToFetch = [];

                this.listsToFetch.push(this.list.list_id);

                if(this.memberOf.length > 0) {

                    this.memberOf.forEach(member => {
                        this.listsToFetch.push(member.list_id);
                    });

                }

                

            } else {    
                this.listsToFetch = [];
            }
 
        },



        combineLists: function() {

            if(this.listsToFetch.length !== 0) {

                this.$store.dispatch('consolidateLists', this.listsToFetch);

            } else {

                var toast = { 
                    show: true,
                    time: 2000,
                    success: false,
                    heading: 'Select at least one list',
                    body: ''
                }

                this.$store.dispatch('showToast', toast);

            }
            
        },

        showListHelp: function() {
            var modal = {
                show: true,
                autoClose: false,
                general: true,
                success: false,
                options: false,
                input: false,
                heading: 'Choosing lists to view',
                body: 'Select all the lists you would like to see together and click combine.',
                data: {} 
        }

        this.$store.dispatch('showModal', modal)
        }
    }

}
</script>

<style>

.select-list-info {
    margin-top: 1em;
    text-align: center;
    padding: 0 4em;
}


.inline-list-select {
    display: flex;
    align-items: center;
}

.list-select-checkbox {
    margin-top: 0.5em;
}




.btn-combine-lists-wrap {
    display: flex;
    justify-content: center;
}


.btn-combine-lists {
    text-align: center;
    margin-top: 3em;
    margin-bottom: 4em;
}



.select-all-checkbox {
    margin-top: 0.5em;
}

</style>