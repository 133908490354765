<template>
  <div class="login-wrap">

      <img class="shoplist-logo login" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
      <h1 class="logo-wording"><span>Shop</span>List</h1>

      <h4 class="input-heading">Email:</h4>
      <input id="emailInput" type="email" class="login-input" v-model="user.email" @focus="error.active = false, error.action = false">
      
      <h4 class="input-heading">Password:</h4>
      <input type="password" class="login-input" v-model="user.password">

      <router-link class="forgot-password-link" to="/password_reset">forgot password</router-link>
      
      <br>

      <div class="login-error-wrap" v-if="error.active">
          <p>{{ error.content }}</p>
          <br>
          <p v-if="error.register" class="login-error-register">Please <router-link to="/register">Register here</router-link> first.</p>
      </div>

      <button class="btn login-btn" @click="doLogin">Login</button>

      <p v-if="promptInstall"><span style="color: rgb(0,150,240);" @click="installApp">Install</span> our app for a better experience</p>

  
  </div>
</template>



<script>
import { mapGetters } from 'vuex';


export default {

    name: 'Login',


    data() {
        return {


            user: {
                email: '',
                password: ''
            },


            error: {
                active: false,
                content: '',
                resetPassword: false,
                register: false,
                ignore: ''
            },

            // Email validation
            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

            promptInstall: false
        }
    },


    computed: {
        ...mapGetters(['install'])
    },




    created() {

        this.$store.dispatch('authUser');

        if(this.install.userChoice) {
            this.promptInstall = true;
        }

    },





    mounted() {
        document.getElementById('emailInput').focus();
    },





    methods: {


        installApp: function() {

            this.install.prompt();

            this.install.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });

        },






        canLogin: function() {


            var flag = true;

            if(this.user.email === '' || !this.regexEmail.test(String(this.user.email).toLowerCase())) {
                
                flag = false

                this.error.active = true;
                this.error.register = false;  
                this.error.content = 'Please enter a valid email address'
                this.error.resetPassword = false;

            }


             else if(this.user.password === '' ) {

                flag = false

                this.error.active = true;
                this.error.register = false;  
                this.error.content = 'Password cannot be empty'
                this.error.resetPassword = false;

            }

            return flag

        },




        doLogin: async function() {

            if(!this.canLogin()) {
                return
            }

            this.$store.dispatch('doLogin', this.user)

                .then(resp => {

                    // No such user registered
                    if(resp.detail === 'user does not exist') {
                        this.error.active = true;
                        this.error.content = `We don't seem to have that email registered`;
                        this.error.register = true; 
                    }


                    // Password incorrect
                    else if (resp.detail === 'password does not match') {
                        this.error.active = true;
                        this.error.register = false;  
                        this.error.content = 'Password incorrect'
                        this.error.resetPassword = true;
                    }
                })
        }
    }
}


</script>



<style>

.login-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin-top: 100px;
}



.login-input, .register-input {
    margin-bottom: 2.5em;
    width: 200px;
    text-align: center;
}



.login-btn {
    margin-top: 0.5em;
    margin-bottom: 2em;
}

.login-error-wrap {
    text-align: center;
    color: rgb(220,0,0);
    padding: 0 1em;
}

.login-error-register {
    color: rgb(40,40,40);
}

.login-error-register a {
    text-decoration: none;
    color: rgb(0, 162, 255);
}





.shoplist-logo.login {
    max-width: 20vw;
    margin-top: 0;
}

.logo-wording, .logo-wording span {
    font-size: 30px;
    margin-bottom: 2em;
}

.logo-wording span {
    color: rgb(0,150,240);
}




.forgot-password-link {
    color: rgb(0,150,240);
    text-decoration: none;
}


</style>