<template>
  <div class="about-wrap">
      <div class="list-info fixed about">
        <span>About Shoplist</span>
      </div>
      <div class="content about"></div>
      <p><span style="color: rgb(0,150,240);">Shoplist</span> is a free mobile/tablet progressive web application that helps you with your shopping.</p>
      <br>
      <p></p>

      <br>

      <div class="features current">
        <h4>Current Features</h4>
        <ul>
          <li>Share your list with other members</li>
          <li>Members can view your list</li>
          <li>Members can edit or add to your list (permission required)</li>
          <li>Combine lists</li>
          <li>Show list's total amount of items</li>
          <li>Show list's total price amount</li>
          <li>Mark off items as you shop</li>
        </ul>
      </div>


      <div class="features planned">
        <h4>Planned Features</h4>
        <ul>
          <li>Offline Support</li>
          
          <li>Categorize your shopping by:</li>

            <ul class="indent">
              <li>Shop</li>
              <li>Isle</li>
            </ul>

          
          <li>Product offers within your area</li>
          
        </ul>
      </div>


  </div>
</template>

<script>
export default {

}
</script>

<style>

.about-wrap {
    text-align: center;
    width: 100%;
    padding: 0 2em;
}

.content.about {
  margin-top: 5em;
}

.list-info.about {
    width: 100%;
    left: 0;
}





.features {
  justify-self: left;
  text-align: left;
  padding: 0 1em;
  margin-bottom: 2em;
}

.features.planned {
  margin-bottom: 5em;
}

.features h4 {
  margin-bottom: 1em;
  color: rgb(0,150,240);
}

.features li {
  margin-bottom: 0.5em;
}

.features .indent {
  margin-left: 1em;
}

</style>