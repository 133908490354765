import Vue from 'vue'
import Vuex from 'vuex'
import axios from './axios.js'
import router from './router.js'

Vue.use(Vuex);

const store = new Vuex.Store({

    strict: process.env.NODE_ENV !== 'production',

    state: {
        installPrompt: {},
        loggedIn: false,
        user: {},
        members: {},
        activeList: '',
        lists: {
            user: {},
            member: {}
        },
        combinedLists: [],
        items: [],
        modal: {},
        toast:  {}
    },





    getters: {

        install: (state) => {
            return state.installPrompt
        },

        user: (state) => {
            return state.user
        },


        modal: (state) => {
            return state.modal
        },

        toast: (state) => {
            return state.toast
        },


        list: (state) => {
            return state.lists.user
        },

        activeList: (state) => {
            return state.activeList
        },

        memberOf: (state) => {
            return state.lists.member ? state.lists.member : false
        },


        items: (state) => {
            return state.items
        },


        members: (state) => {
            return state.members
        },
        
        
    
        menuSettings: (state) => {
            
            //Check user has a list setup
            var hasList = false

            if(state.lists.user.list_id) {
                hasList = true
            }

            
            var data ={
                root: state.user.root,
                hasList: hasList,
                loggedIn: state.loggedIn
            }
            return data;
        },




    },





    actions: {


        saveLastPage (context, page) {

            localStorage.setItem('lastPage', page);

        },



        showModal (context, modal) {

            context.commit('showModal', modal);

        },
        

        closeModal (context) {
            context.commit('closeModal');
        },





        showToast (context, toast) {

            // commit the settings to state
            context.commit('showToast', toast);

            setTimeout(() => {
                context.dispatch('closeToast')
            }, toast.time ? toast.time : 2500) /* <- set time delay before closing modal */

        },


        closeToast (context) {
            context.commit('closeToast');
        },




        clearList (context) {

            axios.post('data/clearList.php', context.state.lists.user.list_id, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('loadListItems', context.state.activeList);
                        context.dispatch('closeModal');
                    }
                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: true,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);

                    }
                })
        },




        addItem (context, item) {

            var data = {
                item: item,
                list_id: context.state.activeList.list_id
            }
            return new Promise((resolve, reject) => {

                axios.post('data/addItem.php', data, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })
    
                    .then(resp => {

                        //console.log(resp);

                        if(resp.data.status === 'success') {
                            context.dispatch('loadListItems', context.state.activeList);
                            resolve();
                        }

                        else {

                            var modal = {
                                show: true,
                                autoClose: false,
                                general: false,
                                success: false,
                                options: false,
                                input: false,
                                heading: 'Database Error',
                                body: 'Please try logging out and back in again',
                                data: {}
                            }
                            context.dispatch('showModal', modal);
                            
                        }
    
                        
                    })
    
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    })

            })

        },




        deleteItem (context, item) {
            
            axios.post('data/deleteItem.php', item,  {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('loadListItems', context.state.activeList)
                        context.dispatch('closeModal')

                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: true,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }
                })
            
            
            
        },




        adjustPrice(context, data) {

            //console.log(data);
            
            axios.post('data/updatePrice.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {
                    //console.log(resp);

                    if(resp.data.status === 'success') {
                        context.dispatch('loadListItems', context.state.activeList);
                        context.commit('closeModal');
                        
                        
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: true,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }
                })

                .catch(err => {
                    console.log(err);
                })
        },




        adjustQty (context, data) {

            //console.log(data);

            var newQty = 0;
            var canExecute = false;


            // First check we can remove something...
            if(data.item.qty >= 2 && data.operator === 'dec') {

                canExecute = true;

            } else if(data.operator === 'inc') {

                canExecute = true;

            }



            // Then check the operator type and mutate data
            if(data.operator === 'inc') {

                newQty = Number(data.item.qty) + 1;

            } else if(data.operator === 'dec') {

                newQty = Number(data.item.qty) - 1;

            }


        
            // Assign data
            const dataToDB =  {
                id: data.item.id,
                list_id : data.item.list_id,
                newQty: newQty
            }
            
            // If our first check passes, we can execute the adjustment
            if(canExecute) {

                axios.post('data/adjustQty.php', dataToDB, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })

                    .then(resp => {


                        if(resp.data.status === 'success') {
                            context.dispatch('loadListItems', context.state.activeList);
                        }

                        else {

                            var modal = {
                                show: true,
                                autoClose: false,
                                general: false,
                                success: false,
                                options: false,
                                input: false,
                                heading: 'Database Error',
                                body: 'Please try logging out and back in again',
                                data: {}
                            }
                            context.dispatch('showModal', modal);
                            
                        }


                    })

                    .catch(err => {
                        console.log(err);
                    })
            }
       
        },




        markComplete( context, data) {

            
            axios.post('data/markComplete.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {
                    
                    if(resp.data.status === 'success') {

                        if(data.consolidate) {
                            context.dispatch('consolidateLists', context.state.combinedLists);
                        } else {
                            context.dispatch('loadListItems', context.state.activeList);
                        }
                        
                        

                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: true,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }
                })

                .catch(err => {
                    console.log(err);
                })
            
        },






        consolidateLists (context, lists) {
            
            // Clear out state.items -> make it an empty array 
            // Set combined lists
            context.commit('prepConsolidate', lists);



            axios.post('data/consolidateLists.php', lists, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {


                    //console.log(resp);

                    if(resp.data.status === 'success') {

                        context.commit('consolidateItems', resp.data.data);

                    }

                    else if (resp.data.status === 'error' && resp.data.detail === 'no items in list') {
                        context.commit('consolidateItems', 'no items');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }


                    
                    if(router.history.current.path !== '/items:consolidate') {
                        router.push('/items:consolidate');
                    }
                    
                    
                })

                .catch(err => {
                    console.log(err);
                })
            
        },






        loadListItems (context, mlist) {
         

            axios.post('data/loadListItems.php', mlist.list_id, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {


                    //console.log(resp);  


                    if(resp.data.status === 'success') {

                        var data = {
                            items: resp.data.data,
                            list: mlist
                        }
                        
                        context.commit('loadItems', data);



                        if(router.history.current.path !== '/items') {
                            router.push('/items');
                        }
                        
                        
                    }

                    else if(resp.data.status === 'error' && resp.data.detail === 'no items in list') {
                        data = {
                            items: 'no items',
                            list: mlist
                        }

                        context.commit('loadItems', data);



                        if(router.history.current.path !== '/items') {
                            router.push('/items');
                        }


                        
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }


                })

                .catch(err => {
                    console.log(err);
                })

        },




        updateListName (context, data) {
            
            axios.post('/data/updateListName.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('closeModal');
                        context.dispatch('getList');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }

                    
                })
        },





        nameList (context, listName) {

            var data = {
                owner: context.state.user.email,
                listName: listName
            }
            
            axios.post('/data/saveListName.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);


                    if(resp.data.status === 'success') {
                        var toast = { 
                            show: true,
                            time: 3500,
                            success: true,
                            heading: 'List name saved',
                            body: ''
                        }
                        context.dispatch('showToast', toast);
                        context.dispatch('getList');
                        router.push('/lists')
                    }

                    else if(resp.data.status === 'error' && resp.data.detail === 'already has a list') {
                        router.push('/items');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }


                })

                .catch(err => {
                    console.log(err);
                })
        },






        getList (context) {

            axios.post('data/getList.php', context.state.user.email, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    
                    if(resp.data.status === 'success') {
                        //console.log(resp.data);
                        context.commit('List', resp.data.data)
                    }

                    else if(resp.data.status === 'error' && resp.data.detail === 'no list under that owner') {
                        router.push('/setup_list');
                    }

                    
                })

                .catch(err => {
                    console.log(err)
                })
        },



        getMemberLists (context) {

            axios.post('data/getMemberLists.php', context.state.user.email, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    if(resp.data.status === 'success') {
                        //console.log(resp.data.data);
                        var lists = resp.data.data;

                        lists.forEach(list => {
                            list.can_add = !!+list.can_add;
                            list.can_edit = !!+list.can_edit;
                        })

                        context.commit('memberLists', lists)
                    }



                })

                .catch(err => {
                    console.log(err);
                })
        },




        getMembers (context) {

            axios.post('data/getMembers.php', context.state.user.email, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    
                    if(resp.data.status === 'success') {

                        var members = resp.data.data;

                        members.map(member => {
                            member.can_edit = !! + member.can_edit;
                            member.can_add = !! + member.can_add;
                        })

                        //console.log(members);
                    
                        context.commit('loadMembers', members);
                        
                    }

                    else if(resp.data.status === 'error' && resp.data.detail === 'no members registered') {

                        context.commit('loadMembers', 'none')
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }

                    

                })
                


                .catch(err => {
                    console.log(err);
                })
        },






        removeMember (context, member) {

            axios.post('data/removeMember.php', member, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('getMembers');
                        context.dispatch('closeModal');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }
                })

                .catch(err => {
                    console.log(err);
                })
        },




        toggleEdit (context, data) {


            axios.post('data/toggleEdit.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('getMembers');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }

                })

        },

        toggleAdd (context,  member) {

            axios.post('data/toggleAdd.php', member, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    //console.log(resp);
                    
                    if(resp.data.status === 'success') {
                        context.dispatch('getMembers');
                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }
                    
                })
        },





        editUserDetails (context, data) {
            
            axios.post('data/updateUserDetails.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp=> {

                    if(resp.data.status === 'success') {

                        context.dispatch('refreshUser');
                        context.dispatch('closeModal');

                    }

                    else {

                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: false,
                            options: false,
                            input: false,
                            heading: 'Database Error',
                            body: 'Please try logging out and back in again',
                            data: {}
                        }
                        context.dispatch('showModal', modal);
                        
                    }

                })
            
        },




        refreshUser (context) {

            axios.post('data/authUser.php', context.state.user.token, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

            .then(resp => {

                if(resp.data.status === 'success') {
                    context.commit('refreshUser', resp.data.data);
                }

                
            })
        },







        doLogin (context, user) {


            return new Promise((resolve,reject) => {

                axios.post('data/login.php', user, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })
    
                    
                    .then(resp => {



                        // Handle successful response here
                        if(resp.data.status === 'success') {
                            
                            
                            // Store token to localStorage
                            localStorage.setItem('token', resp.data.data.token);

                            // Toast user as logged in
                            var toast = { 
                                show: true,
                                time: 3500,
                                success: true,
                                heading: 'Login successful!',
                                body: 'Welcome ' + resp.data.data.name
                            }
                            context.dispatch('showToast', toast);

                            // Commit Login (user)
                            context.commit('login', resp.data.data);


                            // get the user's list from db
                            context.dispatch('getList')


                            // get lists where this user is a member
                            context.dispatch('getMemberLists')


                            // get members of users list
                            context.dispatch('getMembers');

                            
                            // Take user to all the lists
                            router.push('/lists');
                            
                            
                            
                        }



                        // Handle errors on the component -> give responses to user
                        else if(resp.data.status === 'error') {
                            //  console.log(resp);
                            resolve(resp.data);
                        }

                        


                        
                    })


    
                    .catch(err => {

                        console.log(err);
                        reject(err);
                    })

            })
            
            
        },



        authUser(context) {


            if(localStorage.getItem('token') !== null) {

                

                var token = localStorage.getItem('token');

                //console.log(token);

                axios.post('data/authUser.php', token, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })

                    .then(resp => {

                        //console.log(resp);

                        if(resp.data.status === 'success') {


                            // Commit Login (user)
                            context.commit('login', resp.data.data);


                            // get the user's list from db
                            context.dispatch('getList')


                            // get lists where this user is a member
                            context.dispatch('getMemberLists')


                            // get members of users list
                            context.dispatch('getMembers');


                            
                            // Redirect user
                            if(localStorage.getItem('lastPage') !== null) {
                                var lastPageVisited = localStorage.getItem('lastPage');
                                //console.log(lastPageVisited);
                                router.push(lastPageVisited);
                            } else {
                                router.push('/lists')
                            }
                            
                            
                        }

                       

                    })
            }
        },




        doLogout({commit}) {
            // Reset all user related data
            commit('logout');
            commit('closeModal');

            // Push user back to login page
            router.push('/login');
        },





        registerMember (context, member) {

            return new Promise((resolve) => {

                axios.post('data/registerMember.php', member, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })
    
                    .then(resp => {
                        
                        //console.log(resp);
                        context.dispatch('getMemberLists');
                        context.dispatch('getMembers');

                        if(resp.data.status === 'success') {

                            // Relaod the members and member-lists when a new member is registered
                            context.dispatch('getMemberLists');
                            context.dispatch('getMembers');

                            // Let user know it was successful
                            var toast = { 
                                show: true,
                                time: 3500,
                                success: true,
                                heading: member.name,
                                body: 'has been registered to your list'
                            }

                            context.dispatch('showToast', toast);

                            router.push('/members');

                            // Let component know it has been succesful, some in-component updates need to happen
                            resolve('success');
                        }
    
                        if(resp.data.status === 'error' && resp.data.detail === 'already a member of this list') {

                            toast = { 
                                show: true,
                                time: 3500,
                                success: false,
                                heading: member.name,
                                body: 'is already a member of your list'
                            }

                            context.dispatch('showToast', toast);
                            // Let component know, some in-component updates need to happen
                            resolve('already a member');
                        }

                        
                    })
    
                    .catch(err => {
                        console.log(err);
                    })

            })

        },





        registerUser (context, user) {
            return new Promise((resolve,reject) => {

            
                axios.post('data/registerUser.php', user, {
                    headers: {'Content-type' : 'application/x-www-form-urlencoded'}
                })

                    .then(resp => {
                        
                        console.log(resp);

                        if(resp.data.status === 'success') {
                            router.push('/login');

                            var toast = { 
                                show: true,
                                time: 3500,
                                success: true,
                                heading: 'Registration successfull!',
                                body: 'you can now login'
                            }

                            context.dispatch('showToast', toast);
                        }
                        

                        if(resp.data.status === 'error' && resp.data.detail === 'user already exists') {
                            var data = {
                                detail: 'user exists',
                                email: user.email
                            }
                            resolve(data);

                            var modal = {
                                show: true,
                                autoClose: false,
                                general: true,
                                success: false,
                                options: false,
                                input: false,
                                heading: 'Email address already registered',
                                body:   '<p>Somebody might have added you as a member.</p>'
                                        +'<br>'
                                        +'<p>Please check your email (including your spam folder) and use the link provided to complete your registration.</p>',
                                data: {}
                            }
                            context.dispatch('showModal', modal);
                        }
                    })

                    .catch(err => {
                        console.log(err);
                        reject(err);
                    })
            })
        },



        saveInstall (context, prompt) {
            context.commit('saveInstall', prompt);
        }
        
    },





    mutations: {


        saveInstall(state, prompt) {
            state.installPrompt = prompt;
        },


        login(state, user) {
            state.loggedIn = true;
            state.user = user;
        },

        refreshUser(state,user) {
            state.user = user;
        },


        logout(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('lastPage');
            state.loggedIn = false;
            state.user = {};
            state.members = {};
            state.lists = {
                user: {},
                member: {}
            };
            state.items = {};
        },



        showModal(state, modal) {
            state.modal = modal
        },

        closeModal(state) {
            state.modal.show = false
        },



        showToast(state, toast) {
            state.toast = toast
        },

        closeToast(state) {
            state.toast.show = false
        },




        List(state, list) {
            state.lists.user = list
        },

        memberLists(state, lists) {
            state.lists.member = lists
        },

        loadMembers(state, members) {
            state.members = members
        },






        loadItems(state, data) {

            // Sort items first by completion, then by name
            function compare(a,b) {
                return (+a.complete) - (+b.complete) || a.name.localeCompare(b.name);
            }


            if(data.items !== 'no items') {

            
            data.items.forEach(item => {

                item.complete = !! + item.complete;
                item.qty = Number(item.qty)
                item.price = Number(item.price)
                item.id = Number(item.id)

            });
            
            data.items.sort(compare);

            } else {
                data.items = 'no items';
            }


            state.items = data.items;
            state.activeList = data.list;
        },







        prepConsolidate(state, lists) {
            state.combinedLists = lists;
            state.items = [];
        },

        consolidateItems(state, items) {

            // Sort items first by completion, then by name
            function compare(a,b) {
                return (+a.complete) - (+b.complete) || a.name.localeCompare(b.name);
            }

            if(items !== 'no items'){
            
                state.items = items;
  

                state.items.forEach(item => {

                    item.complete = !! + item.complete;
                    item.qty = Number(item.qty)
                    item.price = Number(item.price)
                    item.id = Number(item.id)

                });
                
                state.items.sort(compare);

                state.activeList = 'selected';

            } else {

                state.items = 'no items';

            }
        },

    }
})

export default store;