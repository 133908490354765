<template>
  <div class="complete-reg-wrap">


    <div class="register-wrap" style="margin-top: 2em;">


      <img class="shoplist-logo register" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
      <h1 class="logo-wording register"><span>Shop</span>List</h1>


        <div class="register-main-heading">
            <h2>
                Complete 
                <br>
                Registration
            </h2>
        </div>



        <h4 class="register input-heading" :class="{ error : inputError.name }">Name:</h4>
        <input id="NameInput" type="text" class="register-input name" v-model="newUser.name" :class="{ error : inputError.name }" @focus="inputError.name = false, newUser.name = ''">

        <h4 class="register input-heading" :class="{ error : inputError.lastname }">Lastname:</h4>
        <input type="text" class="register-input lastname" v-model="newUser.lastname" :class="{ error : inputError.lastname }" @focus="inputError.lastname = false, newUser.lastname = ''">
        
        <h4 class="register input-heading" :class="{ error : inputError.email }">Email:</h4>
        <input type="email" disabled class="register-input email-locked" v-model="newUser.email">

        <h4 class="register input-heading">Cellphone: <span class="subtext">(Optional)</span></h4>
        <input type="number" class="register-input cellphone" v-model="newUser.cellphone">

        <h4 class="register input-heading" :class="{ error : inputError.password }">Password:</h4>
        <input type="password" class="register-input password" v-model="newUser.password" :class="{ error : inputError.password }" @focus="inputError.password = false, newUser.password = ''">

        <h4 class="register input-heading" :class="{ error : inputError.passwordConfirm }">Confirm Password:</h4>
        <input type="password" class="register-input password-confirm" v-model="newUser.passwordConfirm" :class="{ error : inputError.passwordConfirm }"  @focus="inputError.passwordConfirm = false, newUser.passwordConfirm = '', inputError.passwordMatch = false">


        <h4 class="register input-heading error" v-if="inputError.passwordMatch">Passwords do not match</h4>
        <h4 class="register input-heading error" v-if="inputError.passwordShort">Password must be at least 6 characters</h4>

        <div class="register-error-wrap" v-if="error.active">
            <p class="register-error-content">{{ error.content }}</p>
            <br>
            <p class="register-error-login" v-if="error.login"><router-link to="/login">Login</router-link></p>
        </div>

        <button class="btn register-btn" @click="completeRegistration">Register</button>



    </div>


  </div>
</template>

<script>

import axios from 'axios'

export default {

  name: 'CompleteReg',

  data() {
    return {


      newUser: {
        name: '',
        lastname: '',
        email: '',
        cellphone: '',
        password: '',
        passwordConfirm: '',
        root: true,
      },

      // Control for CSS
      inputError: {
          name: false,
          lastname: false,
          email: false,
          password: false,
          passwordConfirm: false,
          passwordMatch: false,
          passwordShort: false
      },

      // Display errors
      error: {
          active: false,
          content: '',
          login: false,
          memberExists: false
      },


    }
  },

  created() {


    var token = this.$route.params.mailtoken.slice(1);
    token.toString();


    axios.post('data/getMailToken.php', token, {
      headers: {'Content-type' : 'application/x-www-form-urlencoded'}
    })


      .then(resp => {

        //console.log(resp);
        
        if(resp.data.status === 'success') {

          this.newUser.name = resp.data.data.name;
          this.newUser.lastname = resp.data.data.lastname;
          this.newUser.email = resp.data.data.email;
          this.newUser.mailtoken = resp.data.data.mail_token;

        } else {

          //console.log(resp.data.detail);

        }
      })
  },



  methods: {



    canRegisterUser: function () {

      var flag = true;

      // Check for empty inputs
      for(const [key,  val] of Object.entries(this.newUser)) {

          if(val === '' && key !== 'cellphone') {
              
              // Log fields with errors to console
              //console.log('Error: ' + key + ": " + val);

              this.inputError[key] = true;
              flag = false;
          }
      }


      // Check passwords match
      if(this.newUser.password !== this.newUser.passwordConfirm) {
          flag = false;
          this.inputError.passwordMatch = true
          this.inputError.password = true
          this.inputError.passwordConfirm = true
          this.newUser.password = '';
          this.newUser.passwordConfirm = '';
      }

      if(this.newUser.password.length <= 5) {
          flag = false;
          this.inputError.passwordShort = true;
      }

      return flag

    },



    completeRegistration: function() {

      if(!this.canRegisterUser()) {
          //console.log('There is an input error...');
          return
      }

      //console.log('All is good! Send through to store for registration...');
      axios.post('data/completeRegistration.php', this.newUser, {
        headers: {'Content-type' : 'application/x-www-form-urlencoded'}
      })


        .then(resp => {

          console.log(resp);
            
            if(resp.data.status === 'success') {

              var toast = { 
                show: true,
                time: 3500,
                success: true,
                heading: 'Registration successfull!',
                body: 'you can now login'
              }

              this.$store.dispatch('showToast', toast);

              this.$router.push('/login');

            }

        })



        .catch(err => {
            console.log(err);
        })
    },
  }

}


</script>

<style>


.register-input.email-locked {
  color: rgb(150,150,150);
}


</style>