<template>
    <div class="member-wrap">

        <div v-if="members !== 'none'">

            <div class="list-info">
                Members of {{ list.list_name }}
            </div>



            <div class="member-list-headings">

                <span>Remove</span>
                <span>Email</span>
                <span>Edit</span>
                <span>Add</span>

            </div>




            <div class="member-list" v-for="member in members" :key="member.id">

                <span class="delete-member" @click="delMember(member)">
                    <span class="material-icons">person_remove</span>
                </span>
                <span>({{member.member}})</span>
                <input type="checkbox" :checked="member.can_edit" @click="toggleEdit(member)">
                <input type="checkbox" :checked="member.can_add" @click="toggleAdd(member)">

            </div>


        </div>



        <div class="no-members" v-else>

            <div class="list-info">
                Members of {{ list.list_name }}
            </div>

            <p style="margin-top: 2em">No Members are registered to your list</p>
            <br>
            <p class="register-member-link"><router-link to="/register:member">Register New Member</router-link></p>
        </div>


        <div class="invite-friend-wrap">
            <router-link to="/invite_friend">Invite a friend to join Shoplist</router-link>
        </div>

    </div>
</template>






<script>

import {mapGetters} from 'vuex'
// import axios from 'axios'

export default {

    name: 'Members',

    computed: {
        ...mapGetters(['members']),
        ...mapGetters(['list']),
    },


    methods: {
        
        delMember: function(member) {

            var modal = {
                show: true,
                autoClose: false,
                general: false,
                success: false,
                options: true,
                input: false,
                heading: 'Remove ' + member.member_name + '?',
                body: '',
                data: {
                    member: member,
                }
            }

            // Await user response & react in store
            this.$store.dispatch('showModal', modal)

        },



        toggleEdit: function(member) {

            var data = {
                can_edit: false,
                member: member
            }

            // Toggle can_edit
            if(member.can_edit) {
                data.can_edit = false
            } else {
                data.can_edit = true
            }


            this.$store.dispatch('toggleEdit', data);
        },


        toggleAdd: function(member) {

            var data = {
                can_add: false,
                member: member
            }

            // Toggle can_add
            if(member.can_add) {
                data.can_add = false
            } else {
                data.can_add = true
            }


            this.$store.dispatch('toggleAdd', data);
        }
    }

}
</script>






<style>


.member-wrap {
    width: 100%;
}




.register-member-link a {
    text-decoration: none;
    color: rgb(0, 162, 255);
}



.member-list-headings {
    text-align: center;
    background:  rgb(0, 150, 240);
    padding: 0.6em 0;
    color: white;
    margin-bottom: 0.5em;
}



.member-list, .member-list-headings {
    display: grid;
    grid-template-columns: 0.3fr 1.2fr 0.2fr 0.2fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 0.6em 0.5em;
}

.member-list {
    padding: 0 0.5em;
}

.delete-member {
    color: rgb(220,0,0);
}

.no-members {
    text-align: center;
}


.invite-friend-wrap {
    
    position: fixed;
    bottom: 52px;
    left: 0;
    right: 0;
    margin: 0 auto;

    text-align: center;

}

.invite-friend-wrap a {
    color: rgb(0, 150, 240);
    text-decoration: none;
}

</style>