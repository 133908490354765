<template>

    <div class="install-wrap">

        <div class="list-info fixed install">
            <span>Install Shoplist App</span>
        </div>

        <div class="install-content">


            <div class="install" v-if="promptInstall">
                <h4>Simply click on the button below and follow the prompt</h4>
                <br>
                <button class="btn" @click="installApp">Install</button>
            </div>


            <div class="install" v-else>
                <h4>If Shoplist is not already installed on your device do the following:</h4>
                <br>
                <ol>
                    <li>Open your browser menu (usually in the top right corner)</li>
                    <li>Click on 'Add to Homescreen'</li>
                    <li>Follow the prompt</li>
                </ol>
                <br>
                <br>
                <h4>Make sure you are using one of the following browsers on a mobile device:</h4>
                <br>
                <ul>
                    <li>Chrome</li>
                    <li>Firefox (mobile)</li>
                    <li>Safari (Apple)</li>
                    <li>Microsoft Edge</li>
                </ul>
            </div>


        </div>

    </div>

</template>



<script>

import {mapGetters} from 'vuex'

export default {
    name: 'InstallHowoTo',


    data() {
        return {
            promptInstall: false
        }
    },



    computed: {
        ...mapGetters(['install'])
    },

    created() {

        if(this.install.userChoice) {
            this.promptInstall = true;
        }

    },



    methods: {


        installApp: function() {

            this.install.prompt();

            this.install.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });

        },
    }



}
</script>



<style>

.install-content {
    margin-top: 5em;
    text-align: center;
    padding: 0 2em;
}


.install h4 {
    color: rgb(0,150,240);
}

.install ol, .install ul {
    text-align: left;
}

.install li {
    margin-bottom: 0.8em;
    margin-left: 1em;
}

</style>