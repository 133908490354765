<template>

<div class="single-item-wrap">



  <div class="single-item-grid"  v-if="!consolidate && activeList.can_edit || activeList.list_id === list.list_id">


      <span class="delete-single-item" @click="delItem(item)">
        <span class="material-icons">delete_forever</span>
      </span>

      
      <span class="qty-single-item" :class="{ 'mark-done' : item.complete }">

          <span v-if="!item.complete" class="qty-adjust minus" @click="adjustQty('dec', item)">-</span>
        
          {{ item.qty }}

          <span v-if="!item.complete" class="qty-adjust plus" @click="adjustQty('inc', item)">+</span>
    
      </span>


      <span class="item-single-item" :class="{ 'mark-done' : item.complete }">{{ item.name }}</span>

      <span class="price-single-item" :class="{ 'mark-done' : item.complete }" @click="adjustPrice(item.id)">{{ item.price }}</span>

      <input type="checkbox" :checked="item.complete" class="got-it-single-item" @click="markComplete(item)">
      


  </div>




  <div class="single-item-grid-no-edit" v-if="consolidate || !activeList.can_edit && activeList.list_id !== list.list_id">

      <span class="qty-single-item" :class="{ 'mark-done' : item.complete }">{{ item.qty }}</span>

      <span class="item-single-item" :class="{ 'mark-done' : item.complete }">{{ item.name }}</span>

      <span class="price-single-item" :class="{ 'mark-done' : item.complete }">{{ item.price }}</span>

      <input type="checkbox" :checked="item.complete" class="got-it-single-item" @click="markComplete(item)">

  </div>

</div>

</template>

<script>

import { mapGetters} from 'vuex'


export default {

    name: 'SingleItem',

    props: ['item'],


    computed: {
        ...mapGetters(['activeList']),
        ...mapGetters(['list'])   
    },

    data() {
      return {
        consolidate: false
      }
    },


  created() {

    var path = this.$router.history.current.path;
    path.indexOf('consolidate') !== -1 ? this.consolidate = true : this.consolidate = false;

  },

  updated() {

    var path = this.$router.history.current.path;
    path.indexOf('consolidate') !== -1 ? this.consolidate = true : this.consolidate = false;

  },
    

    methods: {


      delItem: function(item) {
        
        var modal = {
          show: true,
          autoClose: false,
          general: false,
          success: false,
          options: true,
          input: false,
          heading: 'Delete ' + item.name + '?',
          body: '',
          data: {
            item: item,
          }
        }

        // Await user response & react in store
        this.$store.dispatch('showModal', modal)

      },




      adjustPrice: function(id) {

          var modal = {
          show: true,
          autoClose: false,
          general: true,
          success: false,
          options: false,
          input: true,
          heading: 'Enter new item price',
          body: '',
          data: {
            item_id: id,
          }
        }

        this.$store.dispatch('showModal', modal)


      },


      adjustQty(type, item) {

          const data = {
            operator: type,
            item: item
          }

          this.$store.dispatch('adjustQty', data);
                  
      },



      markComplete: function(item) {


        const data = {
          item: item,
          complete: false,
          consolidate: this.consolidate
        }

        if(item.complete) {
          data.complete = false
        } else {
          data.complete = true
        }

        this.$store.dispatch('markComplete', data);

      }




      
    }

}
</script>

<style>

.single-item-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.single-item-grid, .single-item-grid-no-edit {
    width: 100%;
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 0.3fr 0.25fr 0.2fr;
    align-items: center;
    justify-items: center;
    padding: 0 0.5em;
    padding-left: 0.1em;
    text-align: center;
}

.single-item-grid-no-edit {
    grid-template-columns: 0.3fr 0.4fr 0.28fr 0.3fr;
}




.delete-single-item {
  color: rgb(220,0,0);
}

.delete-single-item .material-icons {
  font-size: 28px;
}


.qty-adjust {
  color: rgb(0, 162, 255);
}

.qty-adjust.minus {
  margin-right: 0.3em;
}

.qty-adjust.plus {
  margin-left: 0.3em;
}




.mark-done {
  text-decoration: line-through;
  color: rgb(140,140,140);
}


</style>