<template>
  <div id="app" :class="{ 'rotate-container' : landscape }">
    <Menu v-if="!comp_reg && !landscape"/>
      <router-view v-if="!landscape" class="pages"></router-view>
    <Modal v-if="!landscape"/>
    <Toast v-if="!landscape"/>

    <Landscape v-if="landscape" class="landscape" />
    <!-- FOR TESTING MODAL & TOAST
    <button @click="testModal">Test Modal</button>
    <br>
    <button @click="testToast">Test Toast</button>
    -->
  </div>
</template>





<script>

import Menu from './components/Menu'
import Modal from './components/Modal'
import Toast from './components/Toast'
import Landscape from './components/Landscape'

export default {
  name: 'App',

  components: {
    Menu,
    Modal,
    Toast,
    Landscape
  },



  data() {
    return {
      comp_reg: false,
      landscape: false
    }
  },

  created() {

    // Check for install event and save it
    window.addEventListener('beforeinstallprompt', (e) => {

        e.preventDefault();
        this.$store.dispatch('saveInstall', e);

    })

    window.innerWidth > window.innerHeight + 100 ? this.landscape = true : this.landscape = false

    window.addEventListener('resize', () => {
      window.innerWidth > window.innerHeight + 100 ? this.landscape = true : this.landscape = false
      
    });


    this.$router.history.current.path.indexOf('complete_registration') !== -1 ? this.comp_reg = true : this.comp_reg = false;

  },

  updated() {

    this.$router.history.current.path.indexOf('complete_registration') !== -1 ? this.comp_reg = true : this.comp_reg = false;

  },





  methods: {


    testModal: function() {
      var modal = {
          show: true,
          general: false,
          success: true,
          options: false,
          input: false,
          heading: 'I am the Modal!',
          body: 'Body section...',
          data: {}
      }
      this.$store.dispatch('showModal', modal);
    },


    testToast: function() {
      var toast = { 
        show: true,
        time: 3500,
        success: true,
        heading: 'This is a Toast',
        body: 'body of toast...'
      }
      this.$store.dispatch('showToast', toast)
    },



  }

 
}


</script>





<style>

@keyframes fade-pages-in {
  0% {
    opacity: 0;
  } 
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pages {
  animation: fade-pages-in 600ms ease;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cabin', sans-serif;
  font-size: 16px;
  outline: none;
  max-width: 100%;

}

/* Global Basic Button Styling */
.btn {
  padding: 0.6em 1.2em;
  border: none;
  box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.15);
  background:rgba(255,255,255,0.9);
  border-radius: 3px;
  background:  rgb(0, 162, 255);
  color: white;
}

/* Global Input Styling */
input {
  background: transparent;
  border: none;
  border-bottom: 1px solid  rgb(0, 162, 255);
  padding-bottom: 0.2em;
  font-size: 14px;
}

/* Global Styling Input Headings */
.input-heading {
  margin-bottom: 0.4em;
  color:  rgb(0, 162, 255);
  font-weight: 600;
  font-size: 18px;
}

/* Global Styling for checkboxes */
input[type=checkbox] {
  width: 18px;
  height: 18px;
}


@media (orientation: landscape) {

    .rotate-container {

        width: 100vw;
        height: 100vh;
        
        color:white;
        background: rgba(0,150,240);

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;

    }

    .landscape h1{
      font-size: 50px;
      color: white;
      
    }

}

</style>
