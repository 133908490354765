<template>
  <div class="toast">
    <div class="toast-wrap" :class="{ show : toast.show, success : toast.success, error : !toast.success }">
      <h2 class="toast-heading">{{ toast.heading }}</h2>
      <br v-if="toast.body !== ''">
      <p class="toast-body">{{ toast.body }}</p>
    </div>
  </div>
</template>





<script>

import { mapGetters } from 'vuex'


export default {

  name: 'Toast',

  computed: {
    ...mapGetters(['toast'])
  }


}


</script>



<style>

.toast-wrap {
  z-index: 1000;
  width: 60vw;
  max-width: 80vw;


  position: fixed;
  bottom: -150px;
  left: 0;
  right: 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: rgb(230,230,230);
  border-radius: 5px;
  padding: 1.3em 1em;

  opacity: 0;
  transition: opacity 300ms ease, bottom 250ms ease;
}

.toast-wrap.show {
  bottom: 4em;
  opacity: 1;
  transition: opacity 450ms ease, bottom 350ms ease;
}



.toast-wrap.success {
  background: rgba(220,255,220,0.95);
}

.toast-wrap.error {
  background: rgba(255,200,200,0.9);
}




.toast-heading {
  margin-bottom: 0em;
}

.toast-body {
  text-align: center;
  padding: 0 1em;
}

</style>