<template>
  <div style="margin-top:4em">
      <div class="redirect-to-home">
        <p>This page does not exist on Shoplist</p>
        <br>
        <p><router-link to="/register">Register</router-link> or <router-link to="/login">Login</router-link></p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.redirect-to-home {
  text-align: center;
}

.redirect-to-home a {
  color: rgb(0,150,240);
  text-decoration: none;
}

</style>