<template>
    <div class="add-item-wrapper">

        <div class="add-item-wrap" v-if="active">
            
            <h4 class="input-heading add-item-heading" :class="{ error : inputError.name }">Item Name:</h4>
            <input class="add-item-input input-item-name" id="AddItemInput"  type="text" v-model="newItem.name" :class="{ error : inputError.name }" @input="inputError.name = false">

            <h4 class="input-heading add-item-heading">Price:</h4>
            <input class="add-item-input" type="number" v-model="newItem.price">

            <h4 class="input-heading add-item-heading">Quantity:</h4>
            <input class="add-item-input" type="number" v-model="newItem.qty">

            <div class="add-item-btn-wrap">
            <button class="btn add-item-btn" @click="saveNewItem">Save</button>
            </div>

        </div>

    </div>
</template>

<script>
export default {

    name: 'AddItem',

    props: ['active', 'items'],

    data() {
        return {
            newItem: {
                name: '',
                price: 0,
                qty: 1
            },

            inputError: {
                name: false
            }
        }
    },


    mounted() {
        if(document.getElementById('AddItemInput')) {
            document.getElementById('AddItemInput').focus();
        }
    },


    methods: {

        canSaveItem: function() {

            //console.log(this.items);

            var flag = true;
            
            if(this.items !== 'no items') {
                this.items.forEach(item => {

                    if(item.name === this.newItem.name) {
                        flag = false;

                        var toast = {
                            show: true,
                            time: 3000,
                            success: false,
                            heading: this.newItem.name,
                            body: 'is already on the list'
                        }

                        this.$store.dispatch('showToast', toast);
                    }
                    
                });
            }


            if(this.newItem.name === '') {
                flag = false;
                this.inputError.name = true;

                var toast = {
                    show: true,
                    time: 3000,
                    success: false,
                    heading: 'Item name cannot be empty',
                    body: ''
                }

                this.$store.dispatch('showToast', toast);
            }

            return flag

        },



        saveNewItem: function() {

            if(!this.canSaveItem()) {
                document.getElementById('AddItemInput').focus();
                return
            }

            //console.log('All good, continue with item save...');

            this.$store.dispatch('addItem', this.newItem)

                .then(() => {

                    var toast = {
                        show: true,
                        time: 1500,
                        success: true,
                        heading: this.newItem.name,
                        body: 'has been added to the list'
                    }

                    this.$store.dispatch('showToast', toast);


                    this.newItem.name = '';
                    this.newItem.price = 0;
                    this.newItem.qty = 1;
                    document.getElementById('AddItemInput').focus();
                })

        }
    }

}
</script>

<style>

@keyframes fade-in {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.add-item-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 1em 2em 1.5em 2em;
    column-gap: 1.5em;
    row-gap: 0.6em;
    background: white;
    animation: fade-in 1000ms ease;
}


.add-item-btn-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-column: 1 / span 2;
    z-index: 999;
    margin-top: 0.6em;
}

.add-item-heading {
    margin-top: 0.6em;
    font-size: 16px;
}

.input-heading.error {
    color: rgb(220,0,0);
}

.input-item-name.error {
    border-bottom: 1px solid rgb(220,0,0);
}


</style>