import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store.js'
import Axios from './axios.js'
/*
import VueAxios from 'vue-axios'
import Axios from 'axios'
*/


Vue.config.productionTip = false




// Axios setup
//Vue.use(VueAxios, Axios);

if(process.env.NODE_ENV === 'production') {
  //console.log('prod');
  Axios.defaults.baseURL = 'https://shoplist.bravado.co.za/api/';
} else if(process.env.NODE_ENV === 'development'){
  //console.log('dev');
  Axios.defaults.baseURL = 'http://localhost/shoplist/src/api/';
}



// Setup Service Worker
if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./ServiceWorker.js')
      .then(() => console.log('Service Worker Registered...'))
      .catch((err) => console.log('Service Worker NOT Registered...', err))
} else {
  console.log('Service Worker not available in your browser...');
}



new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
