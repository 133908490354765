<template>
  <div class="donate-wrap">
      <div class="list-info fixed donate">
        <span>Make a donation</span>
      </div>

      <div class="donate-content">
        <p><span style="color: rgb(0,150,240); font-size: 18px">Shoplist</span> is free to use and we plan on keeping it that way.</p>
        <br>
        <p>However, maintaining the app as well as adding new features takes time and we appreciate all the help we can get!</p>
        <br>
        <p>Please consider making a donation.</p>
        <br>
        <p>Thank you,</p>
        <p>The Shoplist Team</p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.donate-content {
  margin-top: 4em;
  text-align: center;
  padding: 0 2em;
}

</style>