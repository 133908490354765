import store from './store.js'
import Page404 from './components/Page404'
import ItemList from './components/ItemList'
import Register from './components/Register'
import Login from './components/Login'
import SetupList from './components/SetupList'
import Lists from './components/Lists'
import Members from './components/Members'
import Settings from './components/Settings'
import CompleteReg from './components/CompleteRegistration'
import ChooseLists from './components/ChooseLists'
import InviteFriend from './components/InviteFriend'
import PasswordReset from './components/PasswordReset'
import PasswordUpdate from './components/PasswordUpdate'
import About from './components/About'
import Bug from './components/Bug'
import Donate from './components/Donate'
import Suggest from './components/Suggest'
import InstallHowTo from './components/InstallHowTo'

export default [


    {
        path: '/',
        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
        }
    },


    {path: 
        '/login', 
        component: Login,
    },



    {
        path: '/register', 
        component: Register
    },
    

    {
        path: '/complete_registration:mailtoken', 
        component: CompleteReg,

    },


    {
        path: '/password_reset', 
        component: PasswordReset,

    },


    {
        path: '/password_update:mailtoken', 
        component: PasswordUpdate,

    },


    {
        path: '/about', 
        component: About,

    },


    {
        path: '/donate', 
        component: Donate,

    },

    {
        path: '/how_to_install', 
        component: InstallHowTo,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },


    {
        path: '/suggestion', 
        component: Suggest,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },


    {
        path: '/bug_report', 
        component: Bug,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },


    
    {
        path: '/register:member', 
        component: Register,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },



    {
        path: '/invite_friend', 
        component: InviteFriend,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },





    {
        path: '/choose_lists', 
        component: ChooseLists,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }

    },



    
    {
        path: '/items',  
        component: ItemList,
        
        beforeEnter: (to, from, next) => {
            
            
            // If user is not logged in -> redirect to Login Page
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                next()
            }
        }
        
    },

    {
        path: '/setup_list',
        component: SetupList,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                next()
            }
        }

    },

    {
        path: '/lists', 
        component: Lists,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }
    },

    {
        path: '/members',
        component: Members,

        beforeEnter: (to, from, next) => {
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }
    },

    
    
    {
        path: '/settings',
        component: Settings,

        beforeEnter: (to, from, next) => {
            
            
            // If user is not logged in -> redirect to Login Page
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                store.dispatch('saveLastPage', to.path);
                next()
            }
        }
        
    },



    {
        path: '/items:consolidate',
        component: ItemList,

        beforeEnter: (to, from, next) => {
            // If user is not logged in -> redirect to Login Page
            if(!store.state.loggedIn) {
                next('/login')
            }
            else {
                next()
            }
        }
        
    },

    {
        path: '*', 
        component: Page404,
    },

    
]