<template>
  <div class="complete-reg-wrap">


    <div class="register-wrap" style="margin-top: 2em;">


        <div class="register-main-heading">
            <h2>
                Invite a Friend 
            </h2>
        </div>



        <h4 class="register input-heading" :class="{ error : inputError.name }">Name:</h4>
        <input id="NameInput" type="text" class="register-input name" v-model="invite.name" :class="{ error : inputError.name }" @focus="inputError.name = false, invite.name = ''">
        
        <h4 class="register input-heading" :class="{ error : inputError.email }">Email:</h4>
        <input type="email" class="register-input" v-model="invite.email" :class="{ error : inputError.email }" @focus="inputError.email = false, invite.email = ''">


        <div class="register-error-wrap" v-if="error.active">
            <p class="register-error-content">{{ error.content }}</p>
            <br>
            <p class="register-error-login" v-if="error.login"><router-link to="/login">Login</router-link></p>
        </div>

        <button class="btn register-btn" @click="sendInvite">Send Invite</button>



    </div>


  </div>
</template>




<script>

import axios from 'axios'
import { mapGetters } from 'vuex';


export default {

    name: 'InviteFriend',

    data() {
        return {
            invite: {
                name: '',
                email: ''
            },

            // Control for CSS
            inputError: {
                name: false,
                email: false,
            },


            // Display errors
            error: {
                active: false,
                content: '',
                memberExists: false
            },

            // Email validation
            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },

    computed: { 
        ...mapGetters(['user'])
    },


    methods: {


            canInvite: function() {

            var flag = true;

            // Check email against regex
            if(!this.regexEmail.test(this.invite.email)) {
                this.inputError.email = true;
                this.invite.email = 'Please enter a valid address';
                flag = false;
            }

            // Check for empty inputs
            for(const [key,  val] of Object.entries(this.invite)) {

                if(val === '') {
                    
                    // Log fields with errors to console
                    //console.log('Error: ' + key + ": " + val);

                    this.inputError[key] = true;
                    flag = false;
                }
            }

            return flag

        },


        sendInvite: function() {

            

            if(!this.canInvite()) {
                return  
            }


            this.invite.user = this.user.name;
            this.invite.user_email = this.user.email;

            
            axios.post('data/inviteFriend.php', this.invite, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {



                    
                    
                    if(resp.data.status === 'success') {

                        var toast = { 
                            show: true,
                            time: 2500,
                            success: true,
                            heading: 'Invitation Sent!',
                            body: ''
                        }

                        this.$store.dispatch('showToast', toast);


                        if(this.$router.history.current.path !== '/members') {
                            this.$router.push('/members');
                        }
                    }





                    if(resp.data.status === 'error' && resp.data.detail === 'user already exists') {
                        
                        toast = { 
                            show: true,
                            time: 2500,
                            success: true, 
                            heading: this.invite.email + ' is already registered',
                            body: ''
                        }

                        this.$store.dispatch('showToast', toast);
                    }
                })
            
        },

    }

}
</script>




<style>

</style>