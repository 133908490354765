<template>
  <div>
    
    <div class="register-wrap" v-if="this.$route.params.member">

        <div class="register-main-heading">
            <h2>Register Member</h2>
            <span class="heading-sub">(add a member to your list)</span>
        </div>



        <h4 class="register input-heading" :class="{ error : inputError.name }">Name:</h4>
        <input id="NameInput" type="text" class="register-input name" v-model="newMember.name" :class="{ error : inputError.name }" @focus="inputError.name = false, newMember.name = ''">

        <h4 class="register input-heading" :class="{ error : inputError.lastname }">Lastname:</h4>
        <input type="text" class="register-input lastname" v-model="newMember.lastname" :class="{ error : inputError.lastname }" @focus="inputError.lastname = false, newMember.lastname = ''">
        
        <h4 class="register input-heading" :class="{ error : inputError.email }">Email:</h4>
        <input type="email" class="register-input email" v-model="newMember.email" :class="{ error : inputError.email }" @focus="inputError.email = false, newMember.email = ''">

        <div class="register-options">
            <h4 class="register input-heading inline">Is {{ newMember.name }} allowed to edit your list?</h4>
            <input type="checkbox" class="register-input" v-model="newMember.can_edit">
        </div>

        <div class="register-options">
            <h4 class="register input-heading inline">Is {{ newMember.name }} allowed to add items your list?</h4>
            <input type="checkbox" class="register-input" v-model="newMember.can_add">
        </div>


        <div class="register-error-wrap" v-if="error.active">
            <p class="register-error-content">{{ error.content }}</p>
            <br>
            <p class="register-error-login" v-if="error.login"><router-link to="/login">Login</router-link></p>
            <p class="register-error-login" v-if="error.memberExists"><router-link to="/members">Go to Members</router-link></p>
        </div>

        <button class="btn register-btn" @click="registerMember">Register</button>


    </div>




    <div class="register-wrap" v-else style="margin-top: 2em;">

      <img class="shoplist-logo register" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
      <h1 class="logo-wording register"><span>Shop</span>List</h1>

        <div class="register-main-heading">
            <h2>
                Register 
                <br>
                Main Account
            </h2>
        </div>



        <h4 class="register input-heading" :class="{ error : inputError.name }">Name:</h4>
        <input id="NameInput" type="text" class="register-input name" v-model="newUser.name" :class="{ error : inputError.name }" @focus="inputError.name = false, newUser.name = ''">

        <h4 class="register input-heading" :class="{ error : inputError.lastname }">Lastname:</h4>
        <input type="text" class="register-input lastname" v-model="newUser.lastname" :class="{ error : inputError.lastname }" @focus="inputError.lastname = false, newUser.lastname = ''">
        
        <h4 class="register input-heading" :class="{ error : inputError.email }">Email:</h4>
        <input type="email" class="register-input email" v-model="newUser.email" :class="{ error : inputError.email }" @focus="inputError.email = false, newUser.email = ''">

        <h4 class="register input-heading">Cellphone: <span class="subtext">(Optional)</span></h4>
        <input type="number" class="register-input cellphone" v-model="newUser.cellphone">

        <h4 class="register input-heading" :class="{ error : inputError.password }">Password:</h4>
        <input type="password" class="register-input password" v-model="newUser.password" :class="{ error : inputError.password }" @focus="inputError.password = false, newUser.password = ''">

        <h4 class="register input-heading" :class="{ error : inputError.passwordConfirm }">Confirm Password:</h4>
        <input type="password" class="register-input password-confirm" v-model="newUser.passwordConfirm" :class="{ error : inputError.passwordConfirm }"  @focus="inputError.passwordConfirm = false, newUser.passwordConfirm = '', inputError.passwordMatch = false">


        <h4 class="register input-heading error" v-if="inputError.passwordMatch">Passwords do not match</h4>
        <h4 class="register input-heading error" v-if="inputError.passwordShort">Password must be at least 6 characters</h4>

        <div class="register-error-wrap" v-if="error.active">
            <p class="register-error-content">{{ error.content }}</p>
            <br>
            <p class="register-error-login" v-if="error.login"><router-link to="/login">Login</router-link></p>
            <p class="register-error-login" v-if="error.memberExists"><router-link to="/members">Go to Members</router-link></p>
        </div>

        <button class="btn register-btn" @click="registerUser">Register</button>



    </div>
    
    
    
  </div>
</template>





<script>

import { mapGetters } from 'vuex'


export default {
    name: 'Register',
    data() {
        return {
            newUser: {
                name: '',
                lastname: '',
                email: '',
                cellphone: '',
                password: '',
                passwordConfirm: '',
                root: true,
            },

            newMember: {
                name: '',
                lastname: '',
                email: '',
                root: false,
                owner: '',
                list_id: '',
                list_name: '',
                can_edit: false,
                can_add: false
            },


            // Control for CSS
            inputError: {
                name: false,
                lastname: false,
                email: false,
                password: false,
                passwordConfirm: false,
                passwordMatch: false,
                passwordShort: false
            },


            // Display errors
            error: {
                active: false,
                content: '',
                login: false,
                memberExists: false
            },


            // Email validation
            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },

    
    
    
    
    
    computed: {
        ...mapGetters(['list']),
        ...mapGetters(['user'])
    },




    methods: {

        canRegisterUser: function () {

            var flag = true;

            // Check email against regex
            if(!this.regexEmail.test(this.newUser.email)) {
                this.inputError.email = true;
                this.newUser.email = 'Please enter a valid address';
                flag = false;
            }

            // Check for empty inputs
            for(const [key,  val] of Object.entries(this.newUser)) {

                if(val === '' && key !== 'cellphone') {
                    
                    // Log fields with errors to console
                    //console.log('Error: ' + key + ": " + val);

                    this.inputError[key] = true;
                    flag = false;
                }
            }


            // Check passwords match
            if(this.newUser.password !== this.newUser.passwordConfirm) {
                flag = false;
                this.inputError.passwordMatch = true
                this.inputError.password = true
                this.inputError.passwordConfirm = true
                this.newUser.password = '';
                this.newUser.passwordConfirm = '';
            }

            if(this.newUser.password.length <= 5) {
                flag = false;
                this.inputError.passwordShort = true;
            }

            return flag

        },



        registerUser: function() {

            if(!this.canRegisterUser()) {
                //console.log('There is an input error...');
                return
            }

            //console.log('All is good! Send through to store for registration...');
            this.$store.dispatch('registerUser', this.newUser)


                .then(resp => {

                    if(resp.detail === 'user exists') {
                        this.error.active = true,
                        this.error.content =  resp.email + ' is already registered',
                        this.error.login = true,
                        this.error.memberExists = false

                        
                    }

                })



                .catch(err => {
                    console.log(err);
                })
        },








        canRegisterMember: function() {

            var flag = true;

            // Check email against regex
            if(!this.regexEmail.test(this.newMember.email)) {
                this.inputError.email = true;
                this.newMember.email = 'Please enter a valid address';
                flag = false;
            }

            // Check for empty inputs
            for(const [key,  val] of Object.entries(this.newMember)) {

                if(val === '' && key !== 'owner' && key !== 'list_id' && key !== 'list_name') {
                    
                    // Log fields with errors to console
                    //console.log('Error: ' + key + ": " + val);

                    this.inputError[key] = true;
                    flag = false;
                }
            }

            return flag

        },


        registerMember: function() {

            

            if(!this.canRegisterMember()) {
                
                return
            }

            // Set owner, list_id and list_name for member storage
            this.newMember.list_id = this.list.list_id;
            this.newMember.list_name = this.list.list_name;
            this.newMember.owner = this.list.owner;
            this.newMember.owner_name = this.user.name;

            this.$store.dispatch('registerMember', this.newMember)

                .then(resp => {
                    if(resp) {
                        // Show "Go To Members Link"
                        this.error.active = true;
                        this.error.memberExists = true;
                        // Reset newMember
                        this.newMember = {
                            name: '',
                            lastname: '',
                            email: '',
                            root: false,
                            owner: '',
                            list_id: '',
                            can_edit: false,
                            can_add: false
                        }
                    }

                })

        }
    }
}


</script>






<style>


.register-wrap {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}




.register-main-heading {
    text-align: center;
    margin-bottom: 2em;
    color:  rgb(0, 162, 255);
}

.register-wrap h2 {
    font-size: 28px;
    margin-top: 1em;
    
}


.register-main-heading .heading-sub {
    font-size: 12px;
}






@keyframes fade-options {
    0%{
        opacity: 0;
    } 
    100% {
        opacity: 1;
    }
}

.register.input-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: color 150ms;
}




.register.input-heading.error {
    color: rgb(220, 0, 0);
    transition: color 250ms;
}

.register.input-heading .subtext {
    font-size: 12px;
}

.register-options {
    display: flex;
    align-items: flex-start;
    animation: fade-options 550ms ease;
}

.register-options .input-heading{
    font-size: 14px;
    text-align: center;
    margin-right: 1em;
}

.register-options .register-input {
    width: auto;
    margin-top: 0.2em;
}




.register-input {
    transition: color 150ms;
    margin-bottom: 2em;
    width: 200px;
    text-align: center;
}

.register-input.error {
    border-bottom: 1px solid  rgb(220, 0, 0);
    color: rgb(220, 0, 0);
    transition: color 250ms;
}



.register-error-wrap {
    text-align: center;
    color: rgb(220,0,0);
    padding: 0 1em;
}

.register-error-register {
    color: rgb(40,40,40);
}

.register-error-login {
    margin-bottom: 1em;
}

.register-error-login a {
    text-decoration: none;
    color: rgb(0, 162, 255);
    
}



.register-btn {
    margin-bottom: 5em;
    margin-top: 1em;
}



.shoplist-logo.register {
    margin-top: 60px;
    max-width: 20vw;
}

.logo-wording.register {
    margin-bottom: 0;
}

</style>