<template>
    <div class="suggestion-wrap">


        <div class="list-info fixed suggestion">
        <span>Make a suggestion</span>
        </div>



        <div class="content suggest">
            <p>Do you have an idea that could make this app better for you?</p>

            <br>

            <h4>Tell us about it!</h4>
            <textarea class="suggest-msg" v-model="msg"></textarea>

            <button class="btn" @click="submitSuggestion">Submit</button>
        </div>

        
    </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'


export default {

    name: 'Suggest',

    data() {
        return {
            msg: ''
        }
    },


    computed: {
        ...mapGetters(['user'])
    },


    methods: {

        submitSuggestion: function() {

            var data = {
                user: this.user.email,
                msg: this.msg
            }
            

            axios.post('data/suggestion.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then((resp) => {

                    if(resp.data.status === 'success'){

                        var toast = { 
                            show: true,
                            time: 3500,
                            success: true,
                            heading: 'Suggestion Sent!',
                            body: 'Thank you for your feedback.'
                        }
                        this.$store.dispatch('showToast', toast);

                        this.msg =  '';

                    }

                    if(resp.data.status === 'error') {

                        toast = { 
                            show: true,
                            time: 3500,
                            success: false,
                            heading: 'Message could not be sent.',
                            body: 'Please try again later'
                        }
                        this.$store.dispatch('showToast', toast);

                    }

                    

                })

        }
    }

}
</script>

<style>

.suggestion-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 2em;
}

.content.suggest {
    margin-top: 5em;
}

.suggest-msg {
    margin-top: 1em;
    margin-bottom: 2em;
    width: 100%;
    height: 150px;
}

</style>