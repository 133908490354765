<template>
    <div class="modal-lightbox" :class="{ show : modal.show }">
        <div class="modal-wrap" :class="{ show : modal.show, error : !modal.success, success : modal.success, general : modal.general}">
        

            <h2 class="modal-heading" :class="{ 'less-margin' : modal.headingOnly }">{{ modal.heading }}</h2>

            <p v-if="modal.body" class="modal-body" v-html="modal.body" :class="{ 'body-margin-less' : modal.autoClose }"></p>






            <div  v-if="modal.options && modal.data.item && !modal.data.logout" class="modal-options-wrap">
                <button class="btn modal-btn cancel" @click="closeModal">No</button>
                <button class="btn modal-btn confirm" @click="deleteItem(modal.data.item)">Yes</button>
            </div>


            <div  v-if="modal.options && modal.data.member && !modal.data.logout" class="modal-options-wrap">
                <button class="btn modal-btn cancel" @click="closeModal">No</button>
                <button class="btn modal-btn confirm" @click="removeMember(modal.data.member)">Yes</button>
            </div>


            <div  v-if="modal.options && modal.data.clearList && !modal.data.logout" class="modal-options-wrap">
                <button class="btn modal-btn cancel" @click="closeModal">No</button>
                <button class="btn modal-btn confirm" @click="clearList()">Yes</button>
            </div>


            <div  v-if="modal.options && modal.data.logout" class="modal-options-wrap">
                <button class="btn modal-btn cancel" @click="closeModal">No</button>
                <button class="btn modal-btn confirm" @click="logout()">Yes</button>
            </div>






            <div v-if="modal.input && !modal.data.user && !modal.data.list_id">
                <input autocomplete="off" id="ModalInput" class="modal-input" type="text" v-model="price" :class="{ 'input-error' : inputError }" @input="inputError = false">
                <button class="btn modal-btn cancel" @click="closeModal">Cancel</button>
                <button class="btn modal-btn" @click="adjustPrice(modal.data.item_id)">Save</button>  
            </div>

            <div v-if="modal.input && modal.data.user && !modal.data.list_id">
                <input autocomplete="off" id="ModalInput" class="modal-input" type="text" v-model="editUserParams" @focus="editUserParams = ''" :class="{ 'input-error' : inputError }" @input="inputError = false">
                <button class="btn modal-btn cancel" @click="closeModal">Cancel</button>
                <button class="btn modal-btn" @click="editUser(modal.data)">Save</button>  
            </div>


            <div v-if="modal.input && modal.data.list_id">
                <input autocomplete="off" id="ModalInput" class="modal-input" type="text" v-model="listName" @focus="listName = ''" :class="{ 'input-error' : inputError }" @input="inputError = false">
                <button class="btn modal-btn cancel" @click="closeModal">Cancel</button>
                <button class="btn modal-btn" @click="updateListName(modal.data)">Save</button>  
            </div>



            <button v-if="!modal.options && !modal.input" class="btn modal-btn" @click="closeModal">Close</button>

        
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'


export default {
    name: 'Modal',


    data() {
        return {
            price: '',
            inputError: false,
            editUserParams: '',
            listName: ''
        }
    },


    computed: {
        ...mapGetters(['modal'])
    },



    updated() {
        if(document.getElementById('ModalInput')) {
            document.getElementById('ModalInput').focus();
        }
    },



    methods: {


        closeModal: function() {
            this.price = '',
            this.inputError = false
            this.$store.dispatch('closeModal');
        },





        deleteItem: function(item) {
            
            this.$store.dispatch('deleteItem', item);
        },





        removeMember: function(member) {

            this.$store.dispatch('removeMember', member);
        },






        canSetPrice: function() {

            Number(this.price);

            if(!isNaN(this.price)) {
                return true
            }

            this.inputError = true;
            return false
        },





        adjustPrice: function(id) {

            if(!this.canSetPrice()) {
                return
            }

            const data = {
                item_id: id,
                price: this.price
            }

            this.$store.dispatch('adjustPrice', data)

            this.price = ''
        },




        clearList: function() {
            this.$store.dispatch('clearList');
        },



        canUpdateParam: function(param) {
            return param === '' ? false : true 
        },




        editUser: function(data) {

            if(!this.canUpdateParam(this.editUserParams)) {
                
                var toast = { 
                    show: true,
                    time: 2000,
                    success: false,
                    heading: 'Cannot be empty',
                    body: ''
                }

                this.$store.dispatch('showToast', toast);

                return
            }

            data.input = this.editUserParams;

            this.$store.dispatch('editUserDetails', data);
        },




        


        updateListName: function(data) {

            if(!this.canUpdateParam(this.listName)) {
                
                var toast = { 
                    show: true,
                    time: 2000,
                    success: false,
                    heading: 'Cannot be empty',
                    body: ''
                }

                this.$store.dispatch('showToast', toast);

                return
            }
            
            data.listName = this.listName;

            this.$store.dispatch('updateListName', data);

        },

        logout: function() {
            this.$store.dispatch('doLogout');
        }


    }


    

}


</script>

<style>


.modal-lightbox {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    opacity: 0;
    transition: left 250ms ease, opacity 350ms ease;
}

.modal-lightbox.show {
    left: 0;
    opacity: 1;
    transition: opacity 550ms ease;
}


.modal-wrap {
    z-index: 1001;
    width: 60vw;
    max-width: 80vw;
    background: rgb(0,200,0);
    padding: 1.3em 1em;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    background: rgba(220,220,255,0.9);
    box-shadow: 0px 0px 12px 0 rgba(0,240,0,0.1);
    /*border: 1px solid rgba(0,240,0,0.8);*/
    
    
    opacity: 0;
    position: fixed;
    bottom: -400px;
    left: 0;
    right: 0;
    margin: 0 auto;

    transition: bottom 700ms ease, opacity 800ms ease, transform 1200ms ease;
}

.modal-wrap.show {
    opacity: 1;
    bottom: 4em;
    transition: bottom 400ms ease, opacity 800ms ease;
}





.modal-wrap.success {
    background: rgba(230,255,230,0.8);
    box-shadow: 0px 0px 20px 0 rgba(0,240,0,0.3);
    border: 1px solid rgba(0,240,0,0.8);
}

.modal-wrap.error {
    background: rgba(255,220,220,0.9);
    box-shadow: 0px 0px 12px 0 rgba(240,0,0,0.5);
    border: 1px solid rgba(240,0,0,0.3);
}

.modal-wrap.general {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 12px 0 rgba(122, 204, 252, 0.8);
    border: 1px solid rgba(0, 0, 255, 0.5);
}





.modal-heading {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
}

.modal-heading.less-margin {
    margin: 0.5em 0;
}




.modal-body {
    margin-bottom: 1.8em;
    
}

.modal-body.body-margin-less {
    margin-bottom: 0.8em;
}





.btn.modal-btn {
    background-color: rgba(255,255,255,0.8);
    color: rgba(0,0,0,0.8);
    
}

.btn.modal-btn.cancel{
    margin-right: 1em;
}

.btn.modal-btn.confirm{
    margin-left: 1em;
}


.modal-input {
    text-align: center;
    margin-bottom: 2em;
}

.modal-input.input-error {
    color: rgb(220,0,0);
    border-bottom: 1px solid rgb(220,0,0);
}

</style>