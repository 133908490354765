<template>
    <div class="menu-wrap">

        <nav :class="{ 'at-top' : !menuSettings.loggedIn }">


            <!-- Server & DB Test 
            <a class="server-test" target="_blank" href="http://localhost/vuex-shoplist/src/api/config/test.php">
                <span class="material-icons">
                    link
                </span>
            </a>
            -->



                <div v-if="menuSettings.loggedIn">
                    <ul>
                        <li v-if="menuSettings.hasList"><router-link to="/register:member"><span class="material-icons add-members">person_add</span></router-link></li>
                        <li><router-link to="/members"><span class="material-icons members">people</span></router-link></li>
                        <li><router-link to="/lists"><span class="material-icons lists">receipt_long</span></router-link></li>
                        <li><router-link to="/choose_lists"><span class="material-icons lists">library_books</span></router-link></li>
                        <li><router-link to="/settings"><span class="material-icons lists">settings</span></router-link></li>
                        <li @click="doLogout"><span class="material-icons">logout</span></li>
                    </ul>
                </div>


                <div v-else>
                    <ul>
                        <li><router-link to="/login">Login</router-link></li>
                        <li><router-link to="/register">Register</router-link></li>
                    </ul>
                </div>



        </nav>


        <div class="nav-spacer"></div>


    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {

    name: 'Menu',

    computed: {
        ...mapGetters(['menuSettings'])
    },

    methods: {
        doLogout: function() {

            var modal = {
                show: true,
                autoClose: false,
                general: false,
                success: true,
                options: true,
                input: false,
                heading: 'Are you sure you want to log out?',
                body: '',
                data: {
                    logout: true
                }
            }

            this.$store.dispatch('showModal', modal)
            
        },

    }

}
</script>

<style>

.server-test {
    position: absolute;
    top: 0.2em;
    left: 0.8em;
    color: white;
}






nav {
    width: 100vw;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0, 139, 219);
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px -4px 6px 0 rgba(0,0,0,0.2);
    z-index: 950;
}

nav.at-top {
    top: 0;
}

.nav-spacer {
    height: 0px;
}

nav ul {
    display: flex;
    align-items: center;
}

nav ul li {
    list-style-type: none;
    margin: 0 1em;
    color:  white;
    cursor: pointer;
}

nav ul li a {   
    text-decoration: none;
    color:  white;
}

.settings {
    padding-top: 0.2em;
    display: flex;
    align-items: center;
}

.settings span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;
    margin: auto;
    color: white;
    font-size: 18px;
}

nav .material-icons.members, nav .material-icons.add-members {
    font-size: 30px;
}

nav .material-icons.lists {
    font-size: 26px;
}


</style>