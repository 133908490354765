<template>
  <div class="item-list-wrap">


    <div class="item-list-fixed" id="FixedContext">


      <div class="list-info" v-if="!consolidate">
        <span>{{ activeList.list_name }}</span>
        <span>{{ activeList.owner }}</span>
      </div>

      <div class="list-info" v-else>
        <span>Combined Shopping List</span>
      </div>

      
      <div v-if="!consolidate">
      <div v-if="activeList.list_id === list.list_id || activeList.can_add" class="add-item-activate" @click="addItemActive = !addItemActive">
        <span class="material-icons">shopping_cart</span>  
        <span>Add Item</span>
        <span class="material-icons handle" :class="{ open : addItemActive }">keyboard_arrow_down</span>
      </div>
      <AddItem v-if="!consolidate" :items="items" :active="addItemActive" />
      </div>


    




      <div v-if="activeList.can_edit || activeList.list_id === list.list_id" class="list-item-headings">

          <span class="heading-delete-item">Del</span>

          <span class="heading-qty-item">Qty</span>

          <span class="heading-item-item">Item</span>

          <span class="heading-price-item">
              Price
              <span class="subtext">tap to edit</span>
          </span>

          <span class="heading-got-it-item">Got It!</span>

      </div>



      <div v-if="!activeList.can_edit && activeList.list_id !== list.list_id" class="list-item-headings no-edit">

          <span class="heading-qty-item">Qty</span>

          <span class="heading-item-item">Item</span>

          <span class="heading-price-item">Price</span>

          <span class="heading-got-it-item">Got It!</span>

      </div>




    </div>


    <div class="items-wrapper" v-if="items !== 'no items'" :style="top" :class="{ 'shift-up' : !addItemActive, 'shift-down' : addItemActive }">
      <div class="single-item-wrap" v-for="item in items" :key="item.id">
        <SingleItem :item="item"/>
      </div>
      
    </div>





    <div class="items-wrapper no-items" v-else :style="top" :class="{ 'shift-up' : !addItemActive, 'shift-down' : addItemActive }">
      
      <p v-if="consolidate">The lists you have selected are empty</p>


      <div v-else>

        <p>No items on this list</p>
        
        <br>
        <p v-if="activeList.list_id === list.list_id || activeList.can_add"><span style="color: rgb(0, 150, 240)" @click="addItemActive = true">Add Items</span></p>

      </div>

    </div>





    <div :style="top" class="clear-list" v-if="activeList.list_id === list.list_id && items !== 'no items' && !consolidate">
      <button class="btn btn-clear-list" @click="clearList">Clear List</button>
    </div>


    <div class="get-list-info-wrap" @click="showListInfo">
        <span class="material-icons get-list-info">info</span>
    </div>


  </div>
</template>






<script>

import { mapGetters } from 'vuex'
import AddItem from './AddItem'
import SingleItem from './SingleItem'


export default {
  name: 'ItemList',

  components: {
    AddItem,
    SingleItem
  },

  data() {
    return {
      addItemActive: false,
      offset: 0,
      consolidate: false,

      listItemCount: 0,
      listTotal: 0
    }
  },

  computed: {
    ...mapGetters(['activeList']),
    ...mapGetters(['items']),
    ...mapGetters(['list']),

    top() {
      return { transform: 'translateY(' + this.offset + 'px)' }
    }

  },

  created() {

    var path = this.$router.history.current.path;
    path.indexOf('consolidate') !== -1 ? this.consolidate = true : this.consolidate = false;

  },

  mounted() {
    
    // Set list offset height
    if(document.getElementById('FixedContext').offsetHeight) {
      this.offset = document.getElementById('FixedContext').offsetHeight;
    }

  },

  updated() {


    // Set list offset height
    if(document.getElementById('FixedContext').offsetHeight) {
      this.offset = document.getElementById('FixedContext').offsetHeight;
    }

    var path = this.$router.history.current.path;
    path.indexOf('consolidate')  !== -1 ? this.consolidate = true : this.consolidate = false;

  },


  methods: {

    clearList: function() {

        var modal = {
          show: true,
          autoClose: false,
          general: false,
          success: false,
          options: true,
          input: false,
          heading: 'Are you sure you want to delete your entire list?',
          body: '',
          data: {
            clearList: true,
          }
        }

        this.$store.dispatch('showModal', modal)

    },


    showListInfo: function() {

      // Reset values
      this.listTotal = 0;
      this.listItemCount = 0;

      // Set list info
      if(this.items !== 'no items') {

        this.items.forEach(item => {

          this.listTotal += item.price * item.qty;
          this.listItemCount ++;
          
        });
      } 


      var modal = {
        show: true,
        autoClose: false,
        general: true,
        success: false,
        options: false,
        input: false,
        heading: 'List Info',
        body: '<p>Items: '+ this.listItemCount +'</p>'
              +'<p>Total Price: '+ this.listTotal +'</p>',
        data: {} 
      }

        this.$store.dispatch('showModal', modal)
    }

  }

  
}

</script>




<style>

.item-list-fixed {
  position: fixed;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 900;
}






.list-info {
  display: flex;
  justify-content: space-around;
  padding: 0.5em 1em;
  background: rgb(0, 95, 155);
  color: white;
}






.add-item-activate {
  cursor: pointer;
  width: 100%;
  background: rgb(0, 68, 107);
  text-align: center;
  padding: 0.3em 0;
  color: white;
  font-weight: 600;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}




.add-item-activate .material-icons.handle {
  position: absolute;
  right: 0.2em;
  transition: transform 350ms ease;
  font-size: 36px;
}

.add-item-activate .material-icons.handle.open {
  transform: rotate(180deg);
  transition: transform 350ms ease;
}



.items-wrapper {
  margin-top: 1.2em;
  padding-bottom: 6em;
}

.items-wrapper.no-items {
  text-align: center;
}

@keyframes fade-in-list {
  0% {
    
    opacity: 0;
  }
  100% {

    opacity: 1;
  }
}

.items-wrapper.shift-up {
  animation: fade-in-list 800ms ease;
}


.items-wrapper.shift-down {
  animation: fade-in-list 800ms ease;
}



.single-item-wrap {
  margin-bottom: 0.4em;
}






.list-item-headings, .list-item-headings-no-edit {
    width: 100%;
    display: grid;
    grid-template-columns: 0.2fr 0.2fr 0.3fr 0.25fr 0.2fr;
    align-items: center;
    justify-items: center;
    padding: 0 0.5em;
    padding-left: 0.1em;
    text-align: center;
    background:  rgb(0, 150, 240);
    padding: 0.6em 0;
    color:  white;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
}

.list-item-headings.no-edit {
    grid-template-columns: 0.3fr 0.4fr 0.28fr 0.3fr;
}

.heading-price-item {
    margin-left: -0.65em;
    display: flex;
    flex-direction: column;

}

.subtext {
    font-size: 12px;
}

.heading-got-it-item {
    margin-left: -0.9em;
}




.clear-list {
  display: flex;
  justify-content: center;
}

.btn-clear-list {
  margin-bottom: 4em;
}



.get-list-info-wrap {
  position: fixed;
  bottom: 52px;
  right: 1em;
  height: 30px;
  width: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
}

.material-icons.get-list-info {
  color :rgb(0,150,240);
  font-size: 24px;
}

</style>