<template>
    <div class="landscape-wrap">


        <img class="shoplist-logo" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
        <h1>ShopList</h1>
        <span>( for mobile and tablet )</span>



        <div class="help-wrap" @click="showHelp = !showHelp">
            <span class="material-icons help">help</span>
        </div>


        <div class="help-info-wrap" :class="{ show : showHelp }">
            <div class="help-info" v-if="showHelp">
                

                <h4 style="color: rgb(0,150,240); font-size: 18px">You are in landscape mode...</h4>
                <br>
                <p>Shoplist is designed to work on mobile devices and tablets in portrait orientation.</p>
                <br>
                <p>Please access this web app from your mobile phone or tablet.</p>

                <button class="btn close-help" @click="showHelp = !showHelp">Okay</button>
            </div>
        </div>



    </div>
</template>

<script>
export default {

    name: 'Landscape',

    data() {
        return {
            showHelp: false
        }
    }

}
</script>

<style>

.landscape-wrap {
    position: relative;
}


.shoplist-logo {
    margin-top: -7em;
    max-width: 10vw;
}

.help-wrap {
    position: fixed;
    bottom: 1em;
    right: 1em;

    border: 1px solid white;
    border-radius: 50%;
    padding: 0.5em;

    display: flex;
    align-items: center;
    justify-content: center;
}

.help-wrap .material-icons.help {
    font-size: 32px;
}



.help-info-wrap {
    opacity: 0;
    transition: opacity 450ms ease;
}

.help-info-wrap.show {
    opacity: 1;
    transition: opacity 450ms ease;
}





.help-info {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 60vw;

    padding: 2em 1em;

    border-radius: 5px;

    background: rgba(255,255,255,1);

    color: black;


}



.help-info p {
    font-size: 14px;
}




.btn.close-help {
    margin-top: 2em;
    font-size: 14px;
}

</style>