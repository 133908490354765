<template>
    <div class="settings-wrap">

      <div class="list-info fixed">
        <span>Settings</span>
      </div>

        
        <div class="list-details">
          <h4 class="list-name-heading">List Name</h4>
          <p>{{ list.list_name }}</p>
          <span class="material-icons edit" @click="editListName">create</span>
        </div>



        <hr>
        <br>


        <div class="personal-details">
          <h4 class="personal-details-heading">Personal details:</h4>




          <div class="details">
            <h4>Name</h4>
            <p class="inline-detail">{{ user.name }}</p>
          </div>
          <span @click="editDetail('name')" class="material-icons edit">create</span>




          <div class="details">
          <h4>Last Name</h4>
          <p class="inline-detail">{{ user.lastname }}</p>
          </div>
          <span @click="editDetail('lastname')" class="material-icons edit">create</span>



          <div class="details">
            <h4>Cellphone</h4>  
            <p class="inline-detail" v-if="Number(user.cellphone) !== 0">{{ user.cellphone }}</p>
            <p class="inline-detail" v-else>No number saved...</p>
          </div>  
          <span class="material-icons edit" @click="editDetail('cellphone')">create</span>
        </div>








        

        <hr>
        <br>
        <br>



        <div class="pass-reset link-wrap">
          <router-link class="pass-reset-link" to="/password_reset">Reset Your Password</router-link>
        </div>
        


        <hr>
        <br>
        <br>


          <div class="install link-wrap">
            <router-link class="about-link" to="/how_to_install">Install Shoplist App</router-link>
          </div>

          <br>


          <div class="about link-wrap">
            <router-link class="about-link" to="/about">About Shoplist</router-link>
          </div>



          <br>

          <div class="suggestions link-wrap">
            <router-link class="about-link" to="/suggestion">Make a suggestion</router-link>
          </div>


          <br>

          <div class="bug-report link-wrap">
            <router-link class="about-link" to="/bug_report">Report a bug</router-link>
          </div>




          <br>
          <!--
          <div class="donate link-wrap">
            <router-link class="about-link" to="/donate">Make a donation</router-link>
          </div>
          -->



        

    </div>
</template>





<script>

import {mapGetters} from 'vuex'

export default {

  name: "Settings",

  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['list'])
  },


  methods: {
    editDetail: function(param) {

      var modal = {
          show: true,
          autoClose: false,
          general: true,
          success: true,
          options: false,
          input: true,
          heading: 'Update your ' +  param,
          body: '',
          data: {
            user: this.user,
            param: param
          }
      }

        if(param === 'lastname') {
          modal.heading = 'Update your last name';
        }

        // Await user response & react in store
        this.$store.dispatch('showModal', modal)
    },


    editListName: function() {

      var modal = {
          show: true,
          autoClose: false,
          general: true,
          success: true,
          options: false,
          input: true,
          heading: 'New list name',
          body: '',
          data: {
            list_id: this.list.list_id
          }
        }

        // Await user response & react in store
        this.$store.dispatch('showModal', modal)
    }


  }
}


</script>






<style>

.settings-wrap hr {
  width: 60vw;
  margin:auto;
}



.list-info.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}




.personal-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 2em;
}

.personal-details-heading {
  grid-column: 1 / span 2;
  justify-self: center;
  margin: 2em 0;
}





.details  {
    padding-left: 2em;
}

.details h4 {
  margin-bottom: 0.3em;
}


.inline-detail {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
}


.material-icons.edit {
  color:  rgb(0, 139, 219);
  font-size: 22px;
}






.list-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 2em;
  
}

.list-name-heading {
  grid-column: 1 / span 2;
  margin-bottom: 1em;
  justify-self: center;
}

.list-details p {
  padding-left: 2em;
}


.pass-reset.link-wrap a {
  color: rgb(0,150,240);
  
}

.pass-reset.link-wrap {
  margin-bottom: 2em;
}






.link-wrap{
  text-align: center;
  width: 100%;
}

.link-wrap a {
  color: rgb(0,150,240);
  text-decoration: none;
}







.link-wrap.bug-report {
  margin-bottom: 6em;
}



</style>