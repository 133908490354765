<template>

    <div class="list-setup-wrap">



        <div class="welcome">
          
          <h2>
              
            Welcome 
        
            {{ this.$store.state.user.name }}!
        
          </h2>
        
        </div>



        <p>It looks like your list doesn't have a name yet,</p>

        <p>let's quickly fix that...</p>

        <br>

        <p>Choose a name that other contributors will easily recognize as yours .</p>

        <br>

        <h4 class="input-heading list-setup">List Name:</h4>
        <input id="ListName" class="list-setup-input" type="text" v-model="listName">
        
        <br>

        <button class="btn list-setup-btn" @click="nameList">Save</button>


    </div>

</template>




<script>



export default {

    name: 'SetupList',

    data() {
        return {
            listName: ''
        }
    },


    mounted() {
        if(document.getElementById('ListName')) {
            document.getElementById('ListName').focus();
        }
    },




    methods: {  


        nameIsValid:function() {

            if(this.listName !== ''){
                return true
            }
            
            var modal = {
                show: true,
                autoClose: true,
                general: false,
                success: false,
                options: false,
                input: false,
                heading: 'Name cannot be empty',
                body: ''
            }

            this.$store.dispatch('showModal', modal);

            document.getElementById('ListName').focus();
            this.listName = '';

            return false
            
        },




        nameList: async function() {

            if(!this.nameIsValid()) {
                console.log('Not a valid name...');
                return
            }

            this.$store.dispatch('nameList', this.listName);
        },

    }

}



</script>




<style>

.list-setup-wrap {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1.5em;
}


.welcome {
    margin: 1em 0;
    margin-top: 4em;
    color:  rgb(0, 162, 255);
}


.list-setup.input-heading {
    margin-top: 2em;
}

.list-setup-input {
    margin-bottom: 2.5em;
    width: 200px;
    text-align: center;
}

.list-setup-btn {
    margin-top: 1.5em;
    margin-bottom: 5em;
}

</style>