<template>
  <div class="login-wrap">

    <img class="shoplist-logo pass-reset" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
    <h1 class="logo-wording"><span>Shop</span>List</h1>

    <div class="pass-reset-heading">
        <h2>
            Password Reset
        </h2>
    </div>

    <p>Enter your email address to reset your password</p>

    <br>

    <h4 class="pass-reset input-heading" :class="{ error : inputError.email }">Email:</h4>
    <input id="emailInput" type="email" class="pass-reset-input email" v-model="user.email" :class="{ error : inputError.email }" @focus="inputError.email = false, user.email = ''">


    <div class="login-error-wrap" v-if="error.active">
        <p>{{ error.content }}</p>
        <br>
        <p v-if="error.register" class="login-error-register">Please <router-link to="/register">Register here</router-link> first.</p>
    </div>
      
    <button class="btn login-btn" @click="resetPassword">Reset Password</button>

      
  
  </div>
</template>




<script>

import axios from 'axios'
import { mapGetters } from 'vuex';


export default {

    name: 'PasswordReset',

    data() {
        return {
            user: {
                email: ''
            },

            error: {
                active: false,
                content: '',
                register: false
            },

            // Control for CSS
            inputError: {
                email: false,
            },

            // Email validation
            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },


    computed: {
        ...mapGetters(['list'])
    },

    mounted() {
        
        if(this.list.owner) {
            this.user.email = this.list.owner;
        }
        
    },



    methods: {

        canReset: function() {


            var flag = true;

            if(this.user.email === '' || !this.regexEmail.test(String(this.user.email).toLowerCase())) {
                
                flag = false

                this.inputError.email = true;  
                this.user.email = 'Please enter a valid email address'

            }

            return flag

        },
        
        resetPassword: function() {

            if(!this.canReset()) {
                return
            }

            axios.post('data/passwordReset.php', this.user, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    if(resp.data.status === 'success') {
                        var modal = {
                            show: true,
                            autoClose: false,
                            general: false,
                            success: true,
                            options: false,
                            input: false,
                            heading: 'Password Reset Sent',
                            body:   '<p>Please check your inbox for an email from us to reset your password.</p>'
                                    +'<br>'
                                    +'<p>You can close this browser window.</p>',
                            data: {} 
                        }

                        this.$store.dispatch('showModal', modal)
                    }
                    
                    
                    if(resp.data.status === 'error' && resp.data.detail === 'user does not exist') {
                        this.error.active = true;
                        this.error.register = true;
                        this.error.content = "We don't seem to have an account with that email address."
                    }
                })
        }
    }

}
</script>




<style>

.pass-reset-heading h2{
    font-size: 28px;
    margin-bottom: 1em;
    color:  rgb(0, 162, 255);
}

.shoplist-logo.pass-reset {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 20vw;
}




.pass-reset-input {
    transition: color 150ms;
    margin-bottom: 2em;
    width: 200px;
    text-align: center;
}

.pass-reset-input.error {
    border-bottom: 1px solid  rgb(220, 0, 0);
    color: rgb(220, 0, 0);
    transition: color 250ms;
}

</style>