<template>
  <div class="bug-wrap">
      <div class="list-info fixed bug-report">
        <span>Report a bug</span>
      </div>

      <div class="content bug">
            <p>Found a bug in the system?</p>

            <br>

            <h4>Please describe what goes wrong</h4>
            <textarea class="bug-msg" v-model="msg"></textarea>

            <button class="btn" @click="submitBugReport">Report Bug</button>
        </div>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'

export default {

    name: 'Bug',

    data() {
      return {
        msg: ''
      }
    },


    computed: {
      ...mapGetters(['user'])
    },


    methods: {

        submitBugReport: function() {

            var data = {
                user: this.user.email,
                msg: this.msg
            }
            

            axios.post('data/reportBug.php', data, {
                headers: {'Content-type' : 'application/x-www-form-urlencoded'}
            })

                .then(resp => {

                    if(resp.data.status === 'success'){

                        var toast = { 
                            show: true,
                            time: 3500,
                            success: true,
                            heading: 'Bug Reported',
                            body: 'Thank you for letting us know!'
                        }
                        this.$store.dispatch('showToast', toast);

                        this.msg =  '';

                    }

                    if(resp.data.status === 'error') {

                        toast = { 
                            show: true,
                            time: 3500,
                            success: false,
                            heading: 'Bug report could not be sent.',
                            body: 'Please try again later'
                        }
                        this.$store.dispatch('showToast', toast);

                    }
                })

        }
    }
}
</script>

<style>

.bug-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0 2em;
}

.content.bug {
    margin-top: 5em;
}

.bug-msg {
    margin-top: 1em;
    margin-bottom: 2em;
    width: 100%;
    height: 150px;
}

</style>