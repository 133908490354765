<template>
  <div class="pass-update-wrap">

    
    
    <img class="shoplist-logo pass-reset" src="@/assets/shoplist_logo.png" alt="Shoplist_logo">
    <h1 class="logo-wording"><span>Shop</span>List</h1>

    <div class="pass-reset-heading">
        <h2>
            Update Password
        </h2>
    </div>



    <div class="pass-update-content" v-if="canReset">

      <h4 class="register input-heading" :class="{ error : inputError.password }">New Password:</h4>
      <input type="password" class="register-input password" v-model="user.password" :class="{ error : inputError.password }" @focus="inputError.password = false, user.password = ''">

      <h4 class="register input-heading" :class="{ error : inputError.passwordConfirm }">Confirm New Password:</h4>
      <input type="password" class="register-input password-confirm" v-model="user.passwordConfirm" :class="{ error : inputError.passwordConfirm }"  @focus="inputError.passwordConfirm = false, user.passwordConfirm = '', inputError.passwordMatch = false, inputError.passwordShort = false">

      <h4 class="register input-heading error" v-if="inputError.passwordMatch">Passwords do not match</h4>
      <h4 class="register input-heading error" v-if="inputError.passwordShort">Password must be at least 6 characters</h4>

      <button class="btn register-btn" @click="updatePassword">Update Password</button>

    </div>



    <div class="no-token" v-else>

      <p>No password reset token was found or the token is out of date.</p>

      <br>
      <router-link to="/password_reset">Password Reset</router-link>

    </div>

  </div>
</template>




<script>

import axios from 'axios'

export default {

    name: 'PasswordUpdate',




    data() {
      return {

        canReset: false,


        userDetails: '',
        
        
        user: {
          password: '',
          passwordConfirm: '',
        },

        // Control for CSS
        inputError: {
            password: false,
            passwordConfirm: false,
            passwordMatch: false,
            passwordShort: false
        },

      }
    },





    created() {
      var token = this.$route.params.mailtoken.slice(1);

      axios.post('data/getUserPassToken.php', token, {
        headers: {'Content-type' : 'application/x-www-form-urlencoded'}
      })

        .then(resp => {
          
          if(resp.data.status === 'success') {

            this.userDetails = resp.data.data;
            this.canReset = true;
            
          }

        })
    },




    methods: {

      canUpdatePassword: function () {

        var flag = true;


        // Check for empty inputs
        for(const [key,  val] of Object.entries(this.user)) {

            if(val === '') {
                
                // Log fields with errors to console
                //console.log('Error: ' + key + ": " + val);

                this.inputError[key] = true;
                flag = false;
            }
        }


        // Check passwords match
        if(this.user.password !== this.user.passwordConfirm) {
            flag = false;
            this.inputError.passwordMatch = true
            this.inputError.password = true
            this.inputError.passwordConfirm = true
            this.user.password = '';
            this.user.passwordConfirm = '';
        }

        if(this.user.password.length <= 5) {
          flag = false;
          this.inputError.passwordShort = true;
        }

        return flag

    },




      updatePassword: function() {


        if(!this.canUpdatePassword()) {
          return
        }

        var data = {
          user_email: this.userDetails.email,
          new_password: this.user.password
        }

        axios.post('data/passwordUpdate.php', data, {
          headers: {'Content-type' : 'application/x-www-form-urlencoded'}
        })

          .then(resp => {
            
            if(resp.data.status === 'success') {

              var modal = {
                show: true,
                autoClose: false,
                general: false,
                success: true,
                options: false,
                input: false,
                heading: 'Password Reset Succesfully',
                body: 'You can now login',
                data: {} 
              }

              this.$store.dispatch('showModal', modal)
              this.$router.push('/login')
            }
          })




      }
    }
}


</script>




<style>

.pass-update-wrap {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pass-update-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.no-token {
  text-align: center;
  padding: 0 2em;
}

.no-token a {
  color: rgb(0,150,240);
  text-decoration: none;
}

</style>